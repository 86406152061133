<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-01-24 17:36:13
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-05-19 09:34:39
-->
<template>
  <!-- 资源分组 -->
  <div class="group-card">
    <el-card class="lf-true">
      <div slot="header" class="fx-sw-cen">
        <div class="tit-mid">{{title}}</div>
        <div class="text-btn-sm cu-p" @click.stop="addGroupHandle" :style="[{'color':$store.state.settings.theme}]">添加</div>
      </div>
      <div :style="[{'--lock-btn': _hexToRgb($store.state.settings.theme)},{'--lock-btn-text':$store.state.settings.theme}]">
        <el-tree
        style="font-size:14px;"
        :data="treeData"
        :props="defaultProps"
        node-key="id"
        :expand-on-click-node="false"
        :highlight-current="true"
        @node-click="handleNodeClick"
        >
          <div
            style="
              flex: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;"
              slot-scope="{ node, data }">
              <el-popover trigger="hover" placement="top" v-if="data.sourceGroup && data.sourceGroup.useDeptName||data.useDeptName">
                <span>{{data.sourceGroup && data.sourceGroup.useDeptName||data.useDeptName}}</span>
                <div slot="reference" class="name-wrapper">
                  {{ node.label }}
                </div>
              </el-popover>
              <span v-else>{{ node.label }}</span>
            <el-dropdown>
              <span>•••</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="editGroupHandle(node)"
                  >编辑</el-dropdown-item
                >
                <el-dropdown-item @click.native="deleteGrouphandle(node)"
                  >删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-tree>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '资源分组'
    },
    treeData: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {

      }
    },
    // 每个树节点用来作为唯一标识的属性
    nodeKey: {
      type: [String, Number],
      default: 'id'
    }
  },
  data () {
    return {
      // defaultProps: {
      //   children: 'childList',
      //   label: 'groupingName'
      // }
    }
  },
  created () {
  },
  methods: {
    editGroupHandle (node) {
      console.log(node, 'kkkkkk')
      // 编辑分组
      this.$emit('editGroupHandle', node)
    },
    deleteGrouphandle (node) {
      // 删除分组
      this.$emit('deleteGrouphandle', node)
    },
    addGroupHandle () {
      // 添加分组
      this.$emit('addGroupHandle')
    },
    handleNodeClick (data) {
      this.$emit('clickGroupNode', data)
    },
    _hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? 'rgba' + '(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + 0.2 + ')' : null
    }
  }
}
</script>

<style lang="scss" scoped>

.lf-true {
  width: 260px;
  margin-right: 8px;

}
/deep/.el-card__body{
  padding: 8px;
}
/deep/.el-tree-node__label {
  font-weight: 500;
  color: #303133;
}
// /deep/.is-current{
//   background: #EBF6FF;
//   color: #0089FF;
// }
/deep/.el-tree-node.is-current>.el-tree-node__content{
    background: #EBF6FF;
    color: #0089FF;
}
/deep/.el-tree-node__content{
  line-height: 26px;
}
/deep/.el-tree-node{
  margin-bottom: 4px;
}
/deep/.el-card__body{
  height:calc(100vh - 460px);
  overflow-y: auto;
}
/deep/.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
  background-color:var(--lock-btn)!important
}

</style>
