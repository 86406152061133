<template>
  <el-dialog
    :visible.sync="editGroupShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="编辑分组"
    width="500px">
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="100px"
      @keyup.enter.native="_submitHandle()">
      <el-form-item label="所属分组">
        <GlobalTreeSelect
          style="width: 275px"
          v-model="dataForm.groupId"
          filterable
          :nodeKey="nodeKey"
          :treeProps="defaultProps"
          :data="treeData"
          @input="_getBelongGroup"
          @deptAllInfo="_getBelongDeptAllInfo">
        </GlobalTreeSelect>
      </el-form-item>
      <el-form-item label="可见范围" prop="useType" v-if="isOneLevel">
        <el-radio-group v-model="dataForm.useType" @input="_useRangeRadioChange">
          <el-radio :label="2">可见范围</el-radio>
          <el-radio :label="1">全部</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="useDeptId" v-if="dataForm.useType==2&&isOneLevel">
        <el-select filterable value-key="id" clearable v-model="dataForm.useDeptId" placeholder="请选择" style="width: 275px">
          <el-option
            v-for="item in visibleDeptList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            @click.native="_selectDept(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="分组名称" prop="groupName">
        <el-input
          clearable
          style="width: 275px"
          v-model="dataForm.groupName"
          placeholder="请输入分组名称"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="_submitHandle()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import { getManageDeptListApi } from '@/api/api'
export default {
  components: { GlobalTreeSelect },
  props: {
    editGroupShow: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default: () => []
    },
    editDataInfo: {
      type: Object,
      default: () => {}
    },
    defaultProps: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    currentNode: {
      type: Object,
      default: () => {}
    },
    pageValueType: {
      type: String,
      default: ''
    },
    nodeKey: {
      type: String,
      default: 'id'
    },
    pageType: {
      type: String,
      default: ''
    },
    pageValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visibleDeptList: [], // 可见部门列表
      isOneLevel: true, // 一级标题展示可见范围字段
      dataForm: {
        groupId: null,
        group: '',
        groupName: '', // 分组名称
        useType: null, // 可见范围
        useDeptId: null,
        useDeptName: null
      },
      dataRule: {
        useType: [
          { required: true, message: '请选择可见范围', trigger: ['change', 'blur'] }
        ],
        useDeptId: [
          { required: true, message: '请选择部门', trigger: ['change', 'blur'] }
        ],
        groupName: [
          { required: true, message: '请选择部门', trigger: ['change', 'blur'] }
        ]
      },
      groupParentInfo: {}
    }
  },
  created () {
    this._getManageDeptList()
    console.log(this.pageValue, this.currentNode, 'this.pageValue')
    if (this.pageValue === 'otherGroup') { // 资源管理、知识库、线上课程、线下课程分组
      // 资源管理不用调详情接口，点击编辑的时候直接从列表获取
      console.log(this.currentNode, 'this.currentNode')
      // this.dataForm = this.currentNode
      this.dataForm.groupName = this.currentNode.groupName
      // 第一级的父级要取id，因为一级的parentId是0，列表匹配不到
      this.dataForm.groupId = this.currentNode.parentId == 0 ? '' : this.currentNode.parentId
      this.dataForm.useType = this.currentNode.useType
      this.dataForm.useDeptId = this.currentNode.useDeptId
      if (this.type === 'resource') {
        this.dataForm.useDeptId = Number(this.currentNode.useDeptId)
      }
    }
    // 判断传的parentId如果为0，可见范围不展示
    if (this.currentNode.parentId === 0) {
      this.isOneLevel = true
    } else {
      this.isOneLevel = false
    }
    // normalGroup--是题库分组,数据回显调用了详情接口，editDataInfo数据
    if (this.pageValueType === 'normalGroup') {
      if (this.currentNode.data.parentId === 0) {
        this.isOneLevel = true
        this.dataForm.groupId = ''
      } else {
        this.isOneLevel = false
        this.dataForm.groupId = this.currentNode.data.parentId
      }
      this.dataForm.groupName = this.currentNode.data.groupName
      this.dataForm.useType = this.editDataInfo.useType
      this.dataForm.useDeptId = this.editDataInfo.useDeptId
    }
  },
  methods: {
    cancel () {
      this.$parent.editGroupShow = false
    },
    _getManageDeptList () {
      getManageDeptListApi().then((res) => {
        if (res.code === 200) {
          this.visibleDeptList = res.data
        }
      })
    },
    _submitHandle () {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.dataForm,
            useType: this.groupParentInfo.useType || this.dataForm.useType,
            useDeptId: this.groupParentInfo.useDeptId || this.dataForm.useDeptId,
            useDeptName: this.groupParentInfo.useDeptName || this.dataForm.useDeptName

          }
          this.$emit('editSubmitGroup', params)
        }
      })
    },
    // // 点击节点的响应
    // _handleNodeClick (node) {
    //   this.dataForm.group = node.groupingName
    //   this.dataForm.parentId = node.id
    //   this.$refs.selectTree.blur()
    // },
    // 所属分组-部门信息
    _getBelongDeptAllInfo (data) {
      if (data) {
        // 当所属分组选择的不是一级分组时--可见范围跟父级保持一致
        if (this.pageType === 'sourceGroup') {
        // 区分分组数据类型
          this.groupParentInfo = data.sourceGroup
        } else {
          this.groupParentInfo = data
        }
        this.isOneLevel = false
      } else {
        this.isOneLevel = true
      }
    },
    // 可见范围选择
    _useRangeRadioChange (value) {
      if (value === 1) {
        this.dataForm.useDeptId = null
      } else {
        if (!this.dataForm.useDeptId) {
          this.dataForm.useDeptId = null
        }
      }
    },
    // 所属分组--取值
    _getBelongGroup (value) {
      this.dataForm.group = value || 0
      this.dataForm.parentId = value || 0
    },
    _selectDept (item) {
      this.dataForm.useDeptId = item.id
      this.dataForm.useDeptName = item.name
    }
  }
}
</script>
<style lang="scss" scoped>
.radius {
  width: 93%;
  background: #f6f7f9;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  margin-top: 8px;
  .radiu-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 14px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
.tag-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.button-new-tag {
  padding-top: 0;
  padding-bottom: 0;
  width: 78px;
  height: 28px;
  text-align: center;
  padding: 8px 9px;
  margin-bottom: 10px;
}
.tag-item {
  margin-right: 10px;
  width: 78px;
  height: 28px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
}
.add-group {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  .add-group-btn {
    width: 102px;

    border: 1px solid #0089ff;
    font-size: 14px;
    color: #0089ff;
  }
}
.add-group-name {
  margin-bottom: 10px;
  width: 298px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 14px;
    height: 14px;
    margin-left: 8px;
    flex: 1;
  }
}
/deep/.el-dialog__body {
  max-height: 420px;
  overflow: auto;
}

</style>
